<template>
  <div class="the-modal" v-show="value">
    <div class="tm__close" @click="$emit('input', false)">
      X
    </div>
    <div class="the-modal-content">
      <b-container>
        <slot></slot>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
.the-modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #fff;
  z-index: 999;
}

.tm__close {
  position: absolute;
  top: 11px;
  right: 22px;
  z-index: 9999;
  font-weight: bold;
  cursor: pointer;
}

.tm__close:hover {
  color: #ff0000;
}

@media print {
  .tm__close {
    opacity: 0;
  }
}
</style>
